import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CardGiftcardRounded} from "@mui/icons-material";
import {Stack, Tooltip} from "@mui/material";
import ERC721 from "../contracts/ERC721.json"

const DialogFormAddErc721 = ({web3, accounts, giftBoxAddress}) => {
  const [open, setOpen] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [id, setId] = React.useState('');

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const addErc721 = async () => {
    closeDialog();
    try {
      const tokenContract = new web3.eth.Contract(ERC721.abi, address);
      await tokenContract.methods.safeTransferFrom(accounts[0], giftBoxAddress, id).send({from: accounts[0]});
    } catch (e) {
      console.error(`Contract ${address} is invalid ERC-721 token`)
    }
  }

  const handleAddressChange = event => setAddress(event.target.value);
  const handleIdChange = event => setId(event.target.value);

  return (
    <>
      <Tooltip title="Add an ERC-721 token to the gift box">
        <Button variant="contained" startIcon={<CardGiftcardRounded/>} onClick={openDialog} size="small">ERC-721</Button>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Add ERC-721 token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Token contract address and ID of the token to add to the gift box.
          </DialogContentText>
          <Stack>
            <TextField value={address}
                       placeholder="0x0000000000000000000000000000000000000000"
                       onChange={handleAddressChange}
                       variant="standard"/>
            <TextField value={id}
                       placeholder="123"
                       onChange={handleIdChange}
                       variant="standard"/>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={addErc721}>Enable</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogFormAddErc721;