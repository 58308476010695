import {Box, Button, Container, Divider, Grid, Stack, Tooltip, Typography} from "@mui/material";
import {Refresh, CalendarMonthRounded, OpenInNew} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import IComplexGiftBox from "../contracts/IComplexGiftBox.json";
import moment from 'moment';
import DialogFormAddEth from "./DialogFormAddEth";
import DialogFormInvestEth from "./DialogFormInvestEth";
import DialogFormAddErc721 from "./DialogFormAddErc721";
import DialogFormAddErc1155 from "./DialogFormAddErc1155";
import ERC20 from "../contracts/ERC20.json"
import DialogFormAddErc777 from "./DialogFormAddErc777";
import DialogFormAddErc20 from "./DialogFormAddErc20";

const Gifted = ({web3, accounts, giftRegistry}) => {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    async function setup() {
      if(giftRegistry === null){return;} //Still loading contracts
      try {
        await getWatchedGiftBoxes();
      } catch (error) {
        console.error("Failed to load given gifts", error);
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    setup();
  });

  const getWatchedGiftBoxes = async () => {
    const boxesList = [];
    const boxes = await giftRegistry.methods.lookupWatchedGiftBoxes().call({from: accounts[0]});
    for(const b of boxes){
      const boxContract = new web3.eth.Contract(IComplexGiftBox.abi, b);
      const giftType = await boxContract.methods.getType().call({from: accounts[0]});
      const recipient = await boxContract.methods.recipient().call({from: accounts[0]});
      let tokenBalance = 0;
      let tokenSymbol = null;
      const giftFeatures = [];
      const giftFeatureOptions = {}

      if(giftType === "AIY-SIMPLE" || giftType === "AIY-COMPLEX"){
        giftFeatures.push("IY");
      }

      if(giftType === "COMPLEX" || giftType === "AIY-COMPLEX") {
        const targetDate = await boxContract.methods.targetDate().call({from: accounts[0]});
        if (targetDate > 0) {
          giftFeatures.push("TD");
          giftFeatureOptions['targetDate'] = targetDate;
        }

        const targetBalance = await boxContract.methods.targetBalance().call({from: accounts[0]});
        if (targetBalance > 0) {
          giftFeatures.push("TB");
          giftFeatureOptions['targetBalance'] = targetBalance;
        }

        const targetTokenBalance = await boxContract.methods.targetTokenBalance().call({from: accounts[0]});
        if (targetTokenBalance > 0) {
          giftFeatures.push("TTB");
          giftFeatureOptions['targetToken'] = await boxContract.methods.targetToken().call({from: accounts[0]});
          giftFeatureOptions['targetTokenBalance'] = targetTokenBalance;
          const tokenContract = new web3.eth.Contract(ERC20.abi, giftFeatureOptions['targetToken']);
          tokenBalance = await tokenContract.methods.balanceOf(b).call({from: accounts[0]})
          tokenSymbol = await tokenContract.methods.symbol().call({from: accounts[0]});
        }
      }

      boxesList.push({
        address: b,
        type: giftType,
        features: giftFeatures,
        featureOptions: giftFeatureOptions,
        to: recipient,
        balance: await web3.eth.getBalance(b),
        tokenBalance: tokenBalance,
        tokenSymbol: tokenSymbol
      });
    }
    setBoxes(boxesList);
  }

  const boxesForUser = []
  for(let i = 0; i < boxes.length; i++){
    boxesForUser.push(
      <Grid item xs={12} key={boxes[i].address}>
        <Divider className="divider"/>
        <Typography variant="h6">Gift box for
          <Button href={"https://mumbai.polygonscan.com/address/"+boxes[i].address} endIcon={<OpenInNew/>}>
            {boxes[i].to}
          </Button>
        </Typography>
        <Typography variant="h7">
          Balance: <b>{boxes[i].balance / 10**18} ETH</b>
          {boxes[i].features.includes("TTB") &&
            <span>, <b>{boxes[i].tokenBalance / 10**18} {boxes[i].tokenSymbol}</b></span>
          }
        </Typography>
        <Stack direction="row" spacing={1}>
          {!boxes[i].features.includes("IY") &&
          <DialogFormAddEth web3={web3} accounts={accounts} giftBoxAddress={boxes[i].address}/>
          }
          {boxes[i].features.includes("IY") &&
          <DialogFormInvestEth web3={web3} accounts={accounts} giftBoxAddress={boxes[i].address}/>
          }
          <DialogFormAddErc20 web3={web3} accounts={accounts} giftBoxAddress={boxes[i].address}/>
          <DialogFormAddErc777 web3={web3} accounts={accounts} giftBoxAddress={boxes[i].address}/>
          <DialogFormAddErc721 web3={web3} accounts={accounts} giftBoxAddress={boxes[i].address}/>
          <DialogFormAddErc1155 web3={web3} accounts={accounts} giftBoxAddress={boxes[i].address}/>
          <Tooltip title="View gift box contract on PolygonScan">
            <Button href={"https://mumbai.polygonscan.com/address/"+boxes[i].address} variant="contained" endIcon={<OpenInNew/>}>
              PolygonScan
            </Button>
          </Tooltip>
        </Stack>
        <Stack direction="row" spacing={1}>
          {boxes[i].features.includes("TD") &&
          <Tooltip title={"Can not be claimed until " + moment.unix(boxes[i].featureOptions.targetDate).format("L") + " at " + moment.unix(boxes[i].featureOptions.targetDate).format("LT")}>
            <Button startIcon={<CalendarMonthRounded/>} color={moment.now() >= moment.unix(boxes[i].featureOptions.targetDate) ? "success" : "warning"} size="small">{moment.unix(boxes[i].featureOptions.targetDate).format("L LT")}</Button>
          </Tooltip>
          }
          {boxes[i].features.includes("TB") &&
          <Tooltip title={"Can not be claimed until box has at least " + boxes[i].featureOptions.targetBalance / 10**18 + " ETH"}>
            <Button size="small" color={boxes[i].balance >= boxes[i].featureOptions.targetBalance ? "success" : "warning"}>ETH > {boxes[i].featureOptions.targetBalance / 10**18}</Button>
          </Tooltip>
          }
          {boxes[i].features.includes("TTB") &&
          <Tooltip title={`Can not be claimed until box has at least ${boxes[i].featureOptions.targetTokenBalance / 10**18} ${boxes[i].tokenSymbol}`}>
            <Button color={boxes[i].tokenBalance >= boxes[i].featureOptions.targetTokenBalance ? "success" : "warning"} size="small">{boxes[i].tokenSymbol} > {boxes[i].featureOptions.targetTokenBalance / 10**18}</Button>
          </Tooltip>
          }
        </Stack>
      </Grid>
    );
  }

 return (
   <Container>
     <Typography variant="h4">Add assets to gifts given to others</Typography>
     <Box>Number of gift boxes found: {boxes.length} <Button variant="contained" startIcon={<Refresh/>} onClick={getWatchedGiftBoxes} size="small">Refresh</Button></Box>
     <Grid container>
       {boxesForUser}
     </Grid>
   </Container>
 );
}

export default Gifted;