import React from "react";
import {Button, Checkbox, Container, FormControlLabel, Grid, Stack, TextField, Typography} from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";

const CreateGift = ({accounts, simpleGiftBoxFactory, complexGiftBoxFactory, aiyComplexGiftBoxFactory}) => {

  const [useInterestYielding, setUseInterestYielding] = React.useState(false);
  const [useTargetDate, setUseTargetDate] = React.useState(false);
  const [useTargetBalance, setUseTargetBalance] = React.useState(false);
  const [useTargetErc20Balance, setUseTargetErc20Balance] = React.useState(false);
  const [useConservator, setUseConservator] = React.useState(false);

  const [recipient, setRecipient] = React.useState('');
  const [targetDate, setTargetDate] = React.useState(moment().add(1, "hour"));
  const [targetBalance, setTargetBalance] = React.useState('');
  const [targetToken, setTargetToken] = React.useState('');
  const [targetTokenBalance, setTargetTokenBalance] = React.useState('');
  const [conservator, setConservator] = React.useState('');

  const toggleUseInterestYielding = () => setUseInterestYielding(!useInterestYielding);
  const toggleUseTargetDate = () => setUseTargetDate(!useTargetDate);
  const toggleUseTargetBalance = () => setUseTargetBalance(!useTargetBalance);
  const toggleUseTargetErc20Balance = () => setUseTargetErc20Balance(!useTargetErc20Balance);
  const toggleUseConservator = () => setUseConservator(!useConservator);

  const createGiftBox = async () => {
    if(useTargetDate || useTargetBalance || useTargetErc20Balance || useConservator){
      //complex gift box optional features
      const _targetDate = useTargetDate ? targetDate.unix() : 0;
      const _targetBalance = useTargetBalance ? (targetBalance * 10**18).toString() : 0;
      const _conservator = useConservator ? conservator : "0x0000000000000000000000000000000000000000";
      const _targetToken = useTargetErc20Balance ? targetToken : "0x0000000000000000000000000000000000000000";
      const _targetTokenBalance = useTargetErc20Balance ? (targetTokenBalance * 10**18).toString() : 0;

      if(useInterestYielding){ //complex gift box with interest yield
        await aiyComplexGiftBoxFactory.methods.createAIYComplexGiftBox(recipient, _conservator, _targetDate, _targetBalance, _targetToken, _targetTokenBalance).send({from: accounts[0]});
      } else { //complex gift box
        await complexGiftBoxFactory.methods.createComplexGiftBox(recipient, _conservator, _targetDate, _targetBalance, _targetToken, _targetTokenBalance).send({from: accounts[0]});
      }
    } else if(useInterestYielding){ //simple gift box with interest yield
      await simpleGiftBoxFactory.methods.createAIYSimpleGiftBox(recipient).send({from: accounts[0]});
    } else { //simple gift box
      await simpleGiftBoxFactory.methods.createSimpleGiftBox(recipient).send({from: accounts[0]});
    }
  };

  // Controlled form inputs
  const handleRecipientChange = event => setRecipient(event.target.value);
  const handleTargetDateChange = value => setTargetDate(value);
  const handleTargetBalanceChange = event => setTargetBalance(event.target.value);
  const handleTargetTokenChange = event => setTargetToken(event.target.value);
  const handleTargetTokenBalanceChange = event => setTargetTokenBalance(event.target.value);
  const handleConservatorChange = event => setConservator(event.target.value);

  return (
    <Container>
      <Typography variant="h4">Create a new gift box</Typography>
      <Typography variant="h5">Choose features - mix and match as desired!</Typography>
      <FormControlLabel control={<Checkbox checked={useInterestYielding} onClick={toggleUseInterestYielding}/>} label="Interest Yielding"/>
      <FormControlLabel control={<Checkbox checked={useTargetDate} onClick={toggleUseTargetDate}/>} label="Redemption Date"/>
      <FormControlLabel control={<Checkbox checked={useTargetBalance} onClick={toggleUseTargetBalance}/>} label="Target Balance"/>
      <FormControlLabel control={<Checkbox checked={useTargetErc20Balance} onClick={toggleUseTargetErc20Balance}/>} label="Target ERC20 Balance"/>
      <span hidden={!useTargetDate && !useTargetBalance && !useTargetErc20Balance}><FormControlLabel control={<Checkbox checked={useConservator} onClick={toggleUseConservator}/>} label="Conservator"/></span>
      <Grid container spacing={4}>
        <Grid item xs={12} alignItems="left" direction="column">
          <TextField id="standard-basic"
                     label="Recipient Address"
                     value={recipient}
                     placeholder="0x0000000000000000000000000000000000000000"
                     onChange={handleRecipientChange}
                     variant="standard"/>
          <Typography>
            Enter the recipient of the gift box - only they will be able to claim to any assets added to the gift box.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!useTargetDate} alignItems="left" direction="column">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Redemption Date"
              value={targetDate}
              minDateTime={moment()}
              onChange={handleTargetDateChange}
              renderInput={params => <TextField {...params} variant="standard"/>}
            />
          </LocalizationProvider>
          <Typography>
            Add a redemption date to the gift box - the recipient must wait until then before they can claim it.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!useTargetBalance} alignItems="left" direction="column">
          <TextField id="standard-basic"
                     label="Target Balance"
                     value={targetBalance}
                     placeholder="0.0"
                     onChange={handleTargetBalanceChange}
                     variant="standard"/>
          <Typography>
            Add a required target balance to the gift box - the recipient won't be able to claim the gift box until
            this balance is met.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!useTargetErc20Balance} alignItems="left" direction="column">
          <Stack direction="row">
            <TextField id="standard-basic"
                       label="Target Token Address"
                       value={targetToken}
                       placeholder="0x0000000000000000000000000000000000000000"
                       onChange={handleTargetTokenChange}
                       variant="standard"/>
            <TextField id="standard-basic"
                       label="Target Token Balance"
                       placeholder="0.0"
                       value={targetTokenBalance}
                       onChange={handleTargetTokenBalanceChange}
                       variant="standard"/>
          </Stack>
          <Typography>
            Add a required target balance to the gift box for a specific ERC-20 token - the recipient won't be able to
            claim the gift box until this balance is met.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!useConservator} alignItems="left" direction="column">
          <TextField id="standard-basic"
                     label="Conservator Address"
                     value={conservator}
                     placeholder="0x0000000000000000000000000000000000000000"
                     onChange={handleConservatorChange}
                     variant="standard"/>
          <Typography>
            Name a conservator for the gift box. The conservator can (only) reduce the requirements or add other
            conservators.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!useInterestYielding} alignItems="left" direction="column">
          <Typography>
            Any ETH added to the gift box will be wrapped as an aToken via AAVE and start yielding interest. When the
            recipient claims their gift the aTokens will be unwrapped for ETH automatically. ERC-20 tokens that AAVE
            support can also be wrapped as aTokens (but don't have to be). They will also be unwrapped automatically
            when claiming the gift.

            This will cause more gas to be used for transactions when wrapping interest yielding funds as they are
            added and when the recipient claims.

            If used in conjunction with one of the target balance features then the underlying assets amount
            will be used as the balance instead of the aToken's balance.
          </Typography>
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" onClick={createGiftBox}>Create gift box</Button>
    </Container>
  );
}

export default CreateGift;