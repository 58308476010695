import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Stack, Tooltip} from "@mui/material";
import ComplexGiftBox from "../contracts/ComplexGiftBox.json"
import ERC20 from "../contracts/ERC20.json";

const DialogFormReduceErc20Requirement = ({web3, accounts, giftBoxAddress}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [requiredBalance, setRequiredBalance] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [tokenSymbol, setTokenSymbol] = React.useState(null);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const addToken = async () => {
    closeDialog();
    try {
      const boxContract = new web3.eth.Contract(ComplexGiftBox.abi, giftBoxAddress);
      //TODO decimals
      boxContract.methods.reduceTargetTokenBalance((amount * 10**18).toString()).send({from: accounts[0]});
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    async function setup() {
      try {
        const boxContract = new web3.eth.Contract(ComplexGiftBox.abi, giftBoxAddress);
        const tokenAddress = await boxContract.methods.targetToken().call({from: accounts[0]})
        const requiredBalance = await boxContract.methods.targetTokenBalance().call({from: accounts[0]});
        setRequiredBalance(requiredBalance);

        // Get token details
        const tokenContract = new web3.eth.Contract(ERC20.abi, tokenAddress);
        setTokenSymbol(await tokenContract.methods.symbol().call({from: accounts[0]}));
      } catch (e) {
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    setup();
  })

  const handleAmountChange = event => setAmount(event.target.value);

  return (
    <>
      <Tooltip title={`Reduce required amount of ${tokenSymbol} for recipient to claim`}>
        <Button variant="contained" onClick={openDialog} size="small">Lessen {tokenSymbol} requirement</Button>
      </Tooltip>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Lessen {tokenSymbol} amount required to claim</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lower the amount of needed {tokenSymbol} tokens before the recipient is allowed to claim their gift.
            <b>Currently {requiredBalance / 10**18} tokens are required</b>
          </DialogContentText>
          <Stack>
            <TextField value={amount}
                       placeholder="123"
                       onChange={handleAmountChange}
                       variant="standard"/>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={addToken}>Reduce requirement</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogFormReduceErc20Requirement;