import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CardGiftcardRounded} from "@mui/icons-material";
import {Stack, Tooltip} from "@mui/material";
import IERC20 from "../contracts/IERC20.json"
import IGiftBox from "../contracts/IGiftBox.json";
import SimpleGiftBox from "../contracts/SimpleGiftBox.json";
import ERC20 from "../contracts/ERC20.json";

const DialogFormAddErc20 = ({web3, accounts, giftBoxAddress}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [amount, setAmount] = React.useState(0);
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [tokenSymbol, setTokenSymbol] = React.useState(null);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const allowErc20 = async () => {
    closeDialog();
    const boxContract = new web3.eth.Contract(IGiftBox.abi, giftBoxAddress);
    await boxContract.methods.allowERC20(address).send({from: accounts[0]});
  }

  const addToken = async () => {
    closeDialog();
    try {
      const tokenContract = new web3.eth.Contract(IERC20.abi, address);
      //TODO decimals
      await tokenContract.methods.transfer(giftBoxAddress, (amount * 10**18).toString()).send({from: accounts[0]});
    } catch (e) {
      console.error(e);
    }
  }

  const handleAddressChange = async event => {
    try {
      setAddress(event.target.value);

      // Check if already enabled
      const boxContract = new web3.eth.Contract(SimpleGiftBox.abi, giftBoxAddress);
      setIsEnabled(await boxContract.methods.isERC20Enabled(event.target.value).call({from: accounts[0]}));

      // Get token details
      const tokenContract = new web3.eth.Contract(ERC20.abi, event.target.value);
      setTokenSymbol(await tokenContract.methods.symbol().call({from: accounts[0]}));
    } catch (e) {}
  }

  const handleAmountChange = event => setAmount(event.target.value);

  return (
    <>
      <Tooltip title="Add ERC20 tokens to the gift box">
        <Button variant="contained" startIcon={<CardGiftcardRounded/>} onClick={openDialog} size="small">ERC-20</Button>
      </Tooltip>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Add ERC-20 tokens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Token contract address and amount of tokens to add. Before adding an ERC-20 token for the first time
            you must enable the gift box to track them (unless it's a required token of the gift box).
          </DialogContentText>
          <Stack>
            <TextField value={address}
                       placeholder="0x0000000000000000000000000000000000000000"
                       onChange={handleAddressChange}
                       variant="standard"/>
            {isEnabled &&
            <TextField value={amount}
                       placeholder="123"
                       onChange={handleAmountChange}
                       variant="standard"/>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          {isEnabled &&
          <Button onClick={addToken}>Gift {tokenSymbol}</Button>
          }
          {!isEnabled &&
          <Button onClick={allowErc20}>Enable {tokenSymbol}</Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogFormAddErc20;