import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Stack, Tooltip} from "@mui/material";
import ComplexGiftBox from "../contracts/ComplexGiftBox.json";

const DialogFormReduceEthRequirement = ({web3, accounts, giftBoxAddress}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const addToken = async () => {
    closeDialog();
    try {
      const boxContract = new web3.eth.Contract(ComplexGiftBox.abi, giftBoxAddress);
      //TODO decimals
      boxContract.methods.reduceTargetBalance((amount * 10**18).toString()).send({from: accounts[0]});
    } catch (e) {
      console.error(e);
    }
  }

  const handleAmountChange = event => setAmount(event.target.value);

  return (
    <>
      <Tooltip title="Reduce the required amount of ETH needed to claim the gift box">
        <Button variant="contained" onClick={openDialog} size="small">Lessen ETH requirement</Button>
      </Tooltip>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Lessen ETH amount required to claim</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lower the amount of needed ETH tokens before the recipient is allowed to claim their gift.
          </DialogContentText>
          <Stack>
            <TextField value={amount}
                       placeholder="123"
                       onChange={handleAmountChange}
                       variant="standard"/>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={addToken}>Reduce requirement</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogFormReduceEthRequirement;