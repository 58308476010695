import React, { useState, useEffect } from "react";
import {Container, Toolbar, Typography} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import GiftRegistry from "./contracts/GiftRegistry.json";
import getWeb3 from "./getWeb3";
import {Routes, Route, Link} from "react-router-dom";
import SimpleGiftBoxFactory from "./contracts/SimpleGiftBoxFactory.json";
import ComplexGiftBoxFactory from "./contracts/ComplexGiftBoxFactory.json";
import AIYComplexGiftBoxFactory from "./contracts/aiyComplexGiftBoxFactory.json";
import CreateGift from "./components/CreateGift";
import Conservatory from "./components/Conservatory";
import Registry from "./components/Registry";
import Gifted from "./components/Gifted";


const App = () => {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [giftRegistry, setGiftRegistry] = useState(null);
  const [simpleGiftBoxFactory, setSimpleGiftBoxFactory] = useState(null);
  const [complexGiftBoxFactory, setComplexGiftBoxFactory] = useState(null);
  const [aiyComplexGiftBoxFactory, setAIYComplexGiftBoxFactory] = useState(null);

  useEffect(() => {
    async function setup() {
      try {
        const web3 = await getWeb3();
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();

        // Load contracts
        const giftRegistry = new web3.eth.Contract(GiftRegistry.abi, GiftRegistry.networks[networkId] && GiftRegistry.networks[networkId].address);
        const simpleGiftBoxFactory = new web3.eth.Contract(SimpleGiftBoxFactory.abi, SimpleGiftBoxFactory.networks[networkId] && SimpleGiftBoxFactory.networks[networkId].address);
        const complexGiftBoxFactory = new web3.eth.Contract(ComplexGiftBoxFactory.abi, ComplexGiftBoxFactory.networks[networkId] && ComplexGiftBoxFactory.networks[networkId].address);
        const aiyComplexGiftBoxFactory = new web3.eth.Contract(AIYComplexGiftBoxFactory.abi, AIYComplexGiftBoxFactory.networks[networkId] && AIYComplexGiftBoxFactory.networks[networkId].address);

        // Set Web3
        setWeb3(web3);
        setAccounts(accounts);

        // Set contracts
        setGiftRegistry(giftRegistry);
        setSimpleGiftBoxFactory(simpleGiftBoxFactory);
        setComplexGiftBoxFactory(complexGiftBoxFactory);
        setAIYComplexGiftBoxFactory(aiyComplexGiftBoxFactory);
      } catch (error) {
        alert("Failed to load web3, accounts, or contract. Check console for details.");
        console.error(error);
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    setup();
  }, []);

  // const classes = useStyles();
  if (!web3) {
    return <Container>Loading Web3, accounts, and contract...</Container>;
  } else {
    return (
      <Container>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}><Link to="/" className="nav-menu-link">Create Gift</Link></Typography>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}><Link to="/gifted" className="nav-menu-link">Give</Link></Typography>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}><Link to="/registry" className="nav-menu-link">Registry</Link></Typography>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}><Link to="/conservatory" className="nav-menu-link">Conservatory</Link></Typography>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/" element={<CreateGift accounts={accounts}
                                               simpleGiftBoxFactory={simpleGiftBoxFactory}
                                               complexGiftBoxFactory={complexGiftBoxFactory}
                                               aiyComplexGiftBoxFactory={aiyComplexGiftBoxFactory}/>} />
          <Route path="/registry" element={<Registry web3={web3}
                                                     accounts={accounts}
                                                     giftRegistry={giftRegistry}/>} />
          <Route path="/conservatory" element={<Conservatory web3={web3}
                                                             accounts={accounts}
                                                             giftRegistry={giftRegistry}/>} />
          <Route path="/gifted" element={<Gifted web3={web3}
                                                 accounts={accounts}
                                                 giftRegistry={giftRegistry}/>} />
        </Routes>
      </Container>
    );
  }
}

export default App;
