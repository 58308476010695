import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Stack, Tooltip} from "@mui/material";
import ComplexGiftBox from "../contracts/ComplexGiftBox.json";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const DialogFormReduceDateRequirement = ({web3, accounts, giftBoxAddress, currentTargetDate}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [targetDate, setTargetDate] = React.useState(moment.unix(currentTargetDate));

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const addToken = async () => {
    closeDialog();
    try {
      const boxContract = new web3.eth.Contract(ComplexGiftBox.abi, giftBoxAddress);
      boxContract.methods.reduceTargetDate(targetDate.unix()).send({from: accounts[0]});
    } catch (e) {
      console.error(e);
    }
  }

  const handleDateChange = value => setTargetDate(value);

  return (
    <>
      <Tooltip title="Reduce the required amount of ETH needed to claim the gift box">
        <Button variant="contained" onClick={openDialog} size="small">Update redemption date</Button>
      </Tooltip>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Update the target redemption date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change the target redemption date for claiming the gift. Can only be made shorter.
          </DialogContentText>
          <Stack>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Redemption Date"
                value={targetDate}
                minDateTime={moment()}
                maxDateTime={moment.unix(currentTargetDate)}
                onChange={handleDateChange}
                renderInput={params => <TextField {...params} variant="standard"/>}
              />
            </LocalizationProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={addToken}>Update redemption date</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogFormReduceDateRequirement;